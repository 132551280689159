import { useMemo } from 'react'
import { Option } from '../models/Option'
import useQuoteCustomBrandsQuery from './useQuoteCustomBrandsQuery'

const useQuoteCustomBrandsOptions = (): Option[] => {
  const brands = useQuoteCustomBrandsQuery()

  return useMemo(() => {
    return brands
      .map((x) => ({
        label: x.name ?? '',
        value: x.name ?? '',
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [brands])
}

export default useQuoteCustomBrandsOptions
