import styled from 'styled-components'

const AnchorScroll = styled.div`
  position: absolute;
  left: 50vw;
  transform: translateY(-${({ theme }) => theme.navbar.mobile.minHeight});

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    transform: translateY(-${({ theme }) => theme.navbar.desktop.minHeight});
  }
`

export default AnchorScroll
