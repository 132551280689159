import { useStaticQuery, graphql } from 'gatsby'
import { QuoteCustomBrandsQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type ISaveQuoteCustomBrand = NonNullable<
  Unpacked<QuoteCustomBrandsQuery['allSaveQuoteCustomBrand']['nodes']>
>

const useQuoteCustomBrandsQuery = (): ISaveQuoteCustomBrand[] => {
  const { allSaveQuoteCustomBrand }: QuoteCustomBrandsQuery = useStaticQuery(
    graphql`
      query QuoteCustomBrands {
        allSaveQuoteCustomBrand {
          nodes {
            name
            mobileModelList {
              name
            }
          }
        }
      }
    `
  )
  return allSaveQuoteCustomBrand.nodes.filter(notEmpty) ?? []
}

export default useQuoteCustomBrandsQuery
