import * as yup from 'yup'
import {
  QuoteCustomFormData,
  GaranteeType,
} from '../models/QuoteCustomFormData'

export const modelCustomValue = '_custom'

const modelRequiredMessage = 'Un nom de modèle est requis'

const warrantyTypes: GaranteeType[] = [
  'out',
  'unknown',
  'broken',
  'ok',
  'oxidized',
]

const modelKey: keyof QuoteCustomFormData = 'modele'
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const quoteCustomFormSchema: yup.SchemaOf<QuoteCustomFormData> = yup
  .object({
    // shop
    shopId: yup.string().required('Un magasin doit être selectionné'),
    // device
    brand: yup.string(),
    modele: yup.string(),
    modeleCustom: yup.string().when(modelKey, {
      is: modelCustomValue,
      then: yup.string().required(modelRequiredMessage),
    }),
    garantee: yup
      .mixed<GaranteeType>()
      .oneOf(
        warrantyTypes,
        'Une valeur doit être séléctionnée pour l‘état de la garantie'
      ),
    description: yup
      .string()
      .required('Une description de la panne de l‘appareil est requise'),
    // contact
    email: yup
      .string()
      .email('L’adresse mail est invalide')
      .required('L’adresse mail est requise'),
    lastname: yup.string().required('Le nom de famille est requis'),
    firstname: yup.string().required('Le prénom est requis'),
    street: yup.string(),
    zip: yup.string().matches(/^[0-9]{5}$/, {
      message: 'Le code postal est invalide',
      excludeEmptyString: true,
    }),
    city: yup.string(),
    howFoundSave: yup.string(),
    optin1: yup.boolean(),
    optin2: yup.boolean(),
    phone: yup.string().matches(phoneRegExp, {
      message: 'Phone number is not valid',
      excludeEmptyString: true,
    }),
  })
  .defined()

export default quoteCustomFormSchema
