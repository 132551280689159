import styled, { css } from 'styled-components'

const marginTop = css`
  margin-top: 8px;
`

const QuoteForm = styled.form`
  display: flex;
  flex-direction: column;

  & > *[class^='RepairContent__CardContainer'] {
    width: 100%;
  }
`

const DeviceContainer = styled.div`
  display: grid;
  gap: 11px;
  padding: 0 8px 8px 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'brand       model'
      'garantee    modelCustom'
      'description description';

    & > *[class^='brand'] {
      grid-area: brand;
    }

    & > *[class^='model'] {
      grid-area: model;
    }

    & > *[class^='modelCustom'] {
      grid-area: modelCustom;
    }

    & > *[class^='garantee'] {
      grid-area: garantee;
    }

    & > *[class^='description'] {
      grid-area: description;
    }
  }
`

const ExternalContactForm = styled.div`
  display: grid;
  padding: 22px;

  & > :nth-child(1) {
    margin-top: 0px;
    grid-area: lastname;
  }

  & > :nth-child(2) {
    margin-top: 22px;
    grid-area: firstname;
  }

  & > :nth-child(3) {
    margin-top: 44px;
    grid-area: email;
  }

  & > :nth-child(4) {
    margin-top: 22px;
    grid-area: phone;
  }

  & > *[class^='Form__Options'] {
    margin-top: 22px;
    grid-area: options;
  }

  & > :nth-child(5) {
    margin-top: 22px;
    grid-area: labelAsterix;
  }

  & > *[class^='brand'] {
    margin-top: 44px;
    grid-area: brand;
  }

  & > *[class^='model'] {
    margin-top: 22px;
    grid-area: model;
  }

  & > *[class^='modelCustom'] {
    margin-top: 22px;
    grid-area: modelCustom;
  }

  & > *[class^='description'] {
    margin-top: 22px;
    grid-area: description;
  }

  & > :last-child {
    margin-top: 22px;
    grid-area: submit;
  }

  grid-template-columns: 1fr;
  grid-template-areas:
    'lastname'
    'firstname'
    'email'
    'phone'
    'brand'
    'model'
    'modelCustom'
    'description'
    'options'
    'labelAsterix'
    'submit';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    column-gap: 88px;
    padding: 44px;

    & > :nth-child(1) {
      margin-top: 0px;
      grid-area: lastname;
    }

    & > :nth-child(2) {
      margin-top: 0px;
      grid-area: firstname;
    }

    & > :nth-child(3) {
      margin-top: 44px;
      grid-area: email;
    }

    & > :nth-child(4) {
      margin-top: 44px;
      grid-area: phone;
    }

    & > :nth-child(5) {
      margin-top: 22px;
      grid-area: labelAsterix;
    }

    & > *[class^='brand'] {
      margin-top: 44px;
      grid-area: brand;
    }

    & > *[class^='model'] {
      margin-top: 44px;
      grid-area: model;
    }

    & > *[class^='modelCustom'] {
      margin-top: 22px;
      grid-area: modelCustom;
    }

    & > *[class^='description'] {
      margin-top: 22px;
      grid-area: description;
    }

    & > :last-child {
      margin-top: 22px;
      grid-area: submit;
    }

    & > *[class^='Form__Options-fvzRrE'] {
      margin-top: 22px;
      grid-area: options;
    }

    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'lastname firstname'
      'email    phone'
      'brand  model'
      '.    modelCustom'
      'description description'
      'options  options'
      'labelAsterix submit';
  }
`

const ContactContainer = styled.div`
  display: grid;
  gap: 11px;
  padding: 0 8px 8px 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'email    email'
      'lastname firstname'
      'address  zip'
      'city     how'
      'options  options';

    & > :nth-child(1) {
      grid-area: email;
    }

    & > :nth-child(2) {
      grid-area: lastname;
    }

    & > :nth-child(3) {
      grid-area: firstname;
    }

    & > :nth-child(4) {
      grid-area: address;
    }

    & > :nth-child(5) {
      grid-area: zip;
    }

    & > :nth-child(6) {
      grid-area: city;
    }

    & > :nth-child(7) {
      grid-area: how;
    }

    & > :nth-child(8) {
      grid-area: options;
    }
  }
`

export default Object.assign(QuoteForm, {
  DeviceContainer,
  ContactContainer,
  ExternalContactForm,
})
