import { useMemo } from 'react'
import { Option } from '../models/Option'
import useQuoteCustomBrandsQuery from './useQuoteCustomBrandsQuery'

const useQuoteCustomModelsOptions = (brandName: string): Option[] => {
  const brands = useQuoteCustomBrandsQuery()

  return useMemo(() => {
    const brand = brands.find((x) => x.name === brandName)
    if (!brand) {
      return []
    }

    return (
      brand.mobileModelList
        ?.map((x) => ({ label: x?.name ?? '', value: x?.name ?? '' }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [brands, brandName])
}

export default useQuoteCustomModelsOptions
